/* Generelles */
@import "reset.scss";
@import "src/mixins/screen.scss";
@import "components/responsive-adapt.scss";

/* Animationen */
@import "src/animations/navbar-animation.scss";
@import "src/animations/entry.scss";

/* Typo */
@import "fonts.scss";
@import "typography.scss";
@import "components/headline1.scss";
@import "components/headline2.scss";
@import "components/headline3.scss";
@import "components/headline4.scss";
@import "components/headline5.scss";
@import "components/bodytext.scss";
@import "components/buttontext.scss";

/* Sections */
@import "sections/hero-section.scss";
@import "sections/about-section.scss";
@import "sections/upcoming-section.scss";
@import "sections/sm-section.scss";
@import "sections/footer-section.scss";
@import "sections/contact-section.scss";
@import "sections/about-full-section.scss";
@import "sections/events-section.scss";
@import "sections/past-section.scss";
@import "sections/booking-section.scss";
@import "sections/about-full-vision.scss";
@import "sections/impressum-section.scss";
@import "sections/datenschutz-section.scss";
@import "sections/product-section.scss";
@import "sections/preorder-section.scss";
@import "sections/abbinder-section.scss";

/* Components */
@import "components/navbar-large.scss";
@import "components/navbar-mobile.scss";
@import "components/navbar-mobile-static.scss";
@import "components/navbar-large-static.scss";
@import "components/tile.scss";
@import "components/button.scss";
@import "components/form.scss";
@import "components/booking-form.scss";